import { AxiosResponse } from 'axios';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { createOzonatorTest, finishOzonatorTest, saveOzonatorTest } from '../../components/queries/testQueries';
import { useTkalDataContext } from '../../context/tkalData';
import { OzonatorPurchaseOrder } from '../../types/directo/OzonatorPurchaseOrder';
import { OzonatorCreateDto, OzonatorTestDto, OZONATOR_TEST_TYPE } from '../../types/ozonator/OzonatTestDto';
import { IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import SelectOzonator from '../../components/configurationForms/ozonator/SelectOzonator';
import OzonatorIdentityForm from '../../components/configurationForms/ozonator/OzonatorIdentityForm';
import OzonatorCasingForm from '../../components/configurationForms/ozonator/OzonatorCasingForm';
import OzonatorPreCheckForm from '../../components/configurationForms/ozonator/OzonatorPreCheckForm';
import OzonatorHardwareForm from '../../components/configurationForms/ozonator/OzonatorHardwareForm';
import OzonatorOperationCheckForm from '../../components/configurationForms/ozonator/OzonatorOperationCheckForm';
import OzonatorLongTest from '../../components/configurationForms/ozonator/OzonatorLongTest';
import OzonatorReinstallSoftware from '../../components/configurationForms/ozonator/OzonatorReinstallSoftware';
import OzonatorPackageForm from '../../components/configurationForms/ozonator/OzonatorPackageForm';
import OzoneGeneratorIdentity from '../../components/configurationForms/ozonator/ozone-generator/OzoneGeneratorIdentity';
import { printProductCode } from '../../components/queries/adminQueries';
import HomeIcon from '@mui/icons-material/Home';
import PrinterModal from '../../components/common/PrinterModal';
import OzGenVisualTestForm from '../../components/configurationForms/ozonator/ozone-generator/OzGenVisualTestForm';
import OzGenFunctionTestForm from '../../components/configurationForms/ozonator/ozone-generator/OzGenFunctionTestForm';

const OzonatorTestPage = () => {
    const { t } = useTranslation();
    const { setIsLoading, isLoading, ozonatorTest, setTestType, setItemNumber, setSavedOzTests, setOzonatorTest, setOzOrderData, defaultPrinter, ozItemData } = useTkalDataContext();
    const [step, setStep] = useState(1);
    let testRaportSteps = 9;
    let ozoneGeneratorTestSteps = 4;

    const chooseFirstStepButtonKey = () => {
        if (step === 1) {
            if (ozonatorTest.id && ozonatorTest.testStatus !== "") return t("Open")
            if (ozonatorTest.id && ozonatorTest.testStatus === "") return t("Next")
            return t("Next")
        }
        if (step > 1) return t("Next")
    }

    const handleCreateTest = () => {
        setIsLoading(true)
        if (ozonatorTest.testType !== OZONATOR_TEST_TYPE.EMPTY) {
            var createDto: OzonatorCreateDto = {
                purchaseOrderNumber: ozonatorTest.purchaseOrderNumber,
                productArticleNumber: ozonatorTest.productArticleNumber, productName: ozonatorTest.productName, productUniqueId: ozonatorTest.productUniqueId, testType: ozonatorTest.testType
            }
            createOzonatorTest(createDto).then((res: AxiosResponse<OzonatorTestDto>) => {
                setOzonatorTest(res.data)
                setStep(step => step + 1)
            }).catch((err) => {
                console.log(err)
                alert("Test on juba olemas")
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    const handleNextButton = () => {
        if (step >= 2) {
            handleSaveTestData()
        }
        if (ozonatorTest.testType === OZONATOR_TEST_TYPE.EMPTY) {
            alert("Valige testi tüüp")
            return;
        }
        if (step === 1 && ozonatorTest.id === 0) {
            handleCreateTest()
            return;
        }

        else {
            setStep(step => step + 1)
            return;
        }
    }

    const handleBackButton = () => {
        if (step >= 2) {
            setStep(step => step - 1)
        }
    }

    const validateNextStep = () => {
        if (step < testRaportSteps && ozonatorTest.testType === OZONATOR_TEST_TYPE.TESTRAPORT) {
            return true;
        }
        if (step < ozoneGeneratorTestSteps && ozonatorTest.testType === OZONATOR_TEST_TYPE.OZONEGENERATOR) {
            return true;
        }
        return false;
    }

    const validateLastStep = () => {
        if (step === testRaportSteps && ozonatorTest.testType === OZONATOR_TEST_TYPE.TESTRAPORT) {
            return true;
        }
        if (step === ozoneGeneratorTestSteps && ozonatorTest.testType === OZONATOR_TEST_TYPE.OZONEGENERATOR) {
            return true;
        }
        return false;
    }

    const canSendTest = () => {
        if (ozonatorTest.testType === OZONATOR_TEST_TYPE.OZONEGENERATOR && step === 4 && (ozonatorTest.measurements['amperage-ozGen']?.statusNew === undefined)) {
            alert("Sisestage voolutugevus ja vajutage nuppu 'Kontrolli'")
            return false;
        }
        return true
    }
    const handleSendTest = async () => {
        if (canSendTest() === false) return;
        setIsLoading(true)
        // Reset the form data and go back to the first step
        try {
            //automatically print label after finishing test, now user manually prints on the second page
            /*  if (ozonatorTest.serialNumber !== null) {
                 if (ozonatorTest.testType === OZONATOR_TEST_TYPE.TESTRAPORT) {
                     await printProductCode(1, defaultPrinter, ozonatorTest.serialNumber, "OzProductCodeLabel")
                 }
                 if (ozonatorTest.testType === OZONATOR_TEST_TYPE.OZONEGENERATOR) {
                     await printProductCode(1, defaultPrinter, ozonatorTest.serialNumber, "OzGeneratorSerialNr")
                 }
             } */
            await saveOzonatorTest(ozonatorTest)
            await finishOzonatorTest(ozonatorTest)
        } catch (err: any) {
            console.log(err.response.data);

        } finally {
            setOzonatorTest({ measurements: {} } as OzonatorTestDto);
            setOzOrderData({} as OzonatorPurchaseOrder)
            setSavedOzTests([])
            setTestType(0)
            setItemNumber("")
            setStep(1);
            alert("Success")
            setIsLoading(false);
        }
    }

    const handleSaveTestData = () => {
        try {
            setIsLoading(true)
            saveOzonatorTest(ozonatorTest);
        }
        catch (err: any) {
            console.log(err.response.data);
            setIsLoading(false);
            return;
        }
        finally {
            setIsLoading(false);
        }
    }
    return (
        <div className="mainPageLayout">
            <div style={{ width: "90%", display: "flex", justifyContent: "flex-end" }}>
                {ozonatorTest.serialNumber !== null && step > 1 ?
                    <div style={{ fontSize: "21px" }}>
                        SN: {ozonatorTest.serialNumber}
                    </div>
                    : <></>
                }
            </div>
            {
                (() => {
                    if (step === 1) {
                        return <SelectOzonator />
                    }
                    switch (ozonatorTest.testType) {
                        case OZONATOR_TEST_TYPE.TESTRAPORT:
                            switch (step) {
                                case 2:
                                    return <OzonatorIdentityForm />
                                case 3:
                                    return <OzonatorCasingForm />
                                case 4:
                                    return <OzonatorPreCheckForm />
                                case 5:
                                    return <OzonatorHardwareForm />
                                case 6:
                                    return <OzonatorOperationCheckForm />
                                case 7:
                                    return <OzonatorLongTest />
                                case 8:
                                    return <OzonatorReinstallSoftware />
                                case 9:
                                    return <OzonatorPackageForm />
                            }
                            break;
                        case OZONATOR_TEST_TYPE.OZONEGENERATOR:
                            switch (step) {
                                case 2:
                                    return <OzoneGeneratorIdentity />
                                case 3:
                                    return <OzGenVisualTestForm />
                                case 4:
                                    return <OzGenFunctionTestForm />
                            }
                            break;
                    }
                })()
            }

            <div className="navigation-buttons-container" >
                {step >= 2 && <Button className="button-primary" onClick={handleBackButton}>{t("Back")}</Button>}

                {step > 1 &&
                    <>
                        <IconButton onClick={() => setStep(1)}>
                            <HomeIcon />
                        </IconButton>
                        <div>
                            <PrinterModal item={ozItemData} isPrintingAllowed={() => true} printFunction={printProductCode} printFunctionArgs={[ozonatorTest.serialNumber]} defaultPrinter="OZ_Printer" printerLabeL={ozItemData.isOzoneGenerator ? "OzGeneratorSerialNr" : "OzProductCodeLabel"} />
                        </div>
                        <IconButton onClick={() => handleSaveTestData()}>
                            <SaveIcon />
                        </IconButton>
                    </>
                }
                {validateNextStep() && <Button className="button-primary" onClick={handleNextButton}>{chooseFirstStepButtonKey()}</Button>}
                {validateLastStep() && <Button className="button-primary" style={{ width: "170px", backgroundColor: "white", color: "red" }} disabled={isLoading || ozonatorTest.testStatus === "Finished"} onClick={handleSendTest}>{t("ConfirmAndSend")} </Button>}
            </div>

        </div>

    )
}

export default OzonatorTestPage