

import { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Modal from '@mui/material/Modal';
import { Box, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import '../../../styles/ahu-test.css'

const useStyles = makeStyles({
    root: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000000a8',
        border: '2px solid #000',
        width: "600px",
        whiteSpace: "pre-line",
    },
    title: {
        marginTop: "10px",
        fontSize: "24px",
        fontFamily: 'Banschrift'
    },
    bodyText: {
        padding: '30px',
        color: 'white',
        textAlign: 'left',
        fontFamily: 'Banschrift',
        fontSize: '18px'
    },
    zoomedImage: {
        width: "100%",
        cursor: "zoom-in",
    },
    zoomedModalImage: {
        width: "90%",
        maxHeight: "90vh",
        objectFit: "contain",
        cursor: "zoom-out",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
    },
    zoomedImageModal: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1500,
    }
});

type Props = { title: string, bodyText: string, picture?: string };

const InfoModal = ({ title, bodyText, picture }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isZoomed, setIsZoomed] = useState(false);
    const styles = useStyles();
    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const toggleZoom = () => {
        setIsZoomed(prev => !prev);
    };

    return (
        <>
            <div className="grid-item-info-button" onClick={handleOpen}>
                <IconButton><InfoIcon /></IconButton>
            </div>

            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={styles.root}>
                    <Typography className={styles.title} id="modal-modal-title" variant="h6" component="h2">
                        {title}
                    </Typography>
                    <Typography className={styles.bodyText} id="modal-modal-description">
                        {bodyText}
                        {picture && <img className={styles.zoomedImage} src={picture} alt="modal" title="Täpsustav pilt" onClick={toggleZoom} />}
                    </Typography>
                    {isZoomed && (
                        <Modal open={isZoomed}
                            onClose={toggleZoom}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description">
                            <div className={styles.zoomedImageModal} onClick={toggleZoom}>
                                <img
                                    className={styles.zoomedModalImage}
                                    src={picture}
                                    alt="zoomed"
                                />
                            </div>
                        </Modal>
                    )}
                </Box>
            </Modal>
        </>
    );
}
export default InfoModal;
