import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap';
import InfoModal from '../../ahu-estonia/InfoModal';
import { CheckboxOptionsThumbsOnly, CheckboxDefaultValue } from '../../ahu/common/checkboxConfig';
import CheckboxGroupOzonator from '../common/CheckboxGroupOzonator';
import MeasurementFieldOzonator from '../common/MeasurementFieldOzonator';
import CeramicPlates from '../../../../graphics/ozone-generator/koroonaplaadid_working.jpg'
import { useTkalDataContext } from '../../../../context/tkalData';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import SelectPrinter from '../../../common/SelectPrinter';

const OzGenFunctionTestForm = () => {
    const { t } = useTranslation();
    const { ozonatorTest, setOzonatorTest } = useTkalDataContext();
    const handleCheckDimensions = (propertyName: string) => {
        if (propertyName === 'amperage-ozGen') {
            if (ozonatorTest.measurements[propertyName]?.value === null) alert("Täidke kõigepealt voolutugevuse lahter")
            if ((Math.abs(+ozonatorTest.measurements[propertyName]?.value) >= 0.35) && (Math.abs(+ozonatorTest.measurements[propertyName]?.value) <= 0.65)) {
                setOzonatorTest({
                    ...ozonatorTest,
                    "measurements": { ...ozonatorTest.measurements, [propertyName]: { ...ozonatorTest.measurements[propertyName], "statusNew": "OK" } }
                })
                return;
            }
            else {
                setOzonatorTest({
                    ...ozonatorTest,
                    "measurements": { ...ozonatorTest.measurements, [propertyName]: { ...ozonatorTest.measurements[propertyName], "statusNew": "FAIL" } }
                })
                return;
            }
        }
    }

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Funktsionaalsuse kontroll")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="flexboxAlignedColumn" style={{ gap: "15px", width: "525px" }}>
                    <div className="flexboxAlignedRow" style={{ width: "100%" }}>
                        <Label className='ahutest-form__input --value' style={{ marginLeft: "20px", border: "none", width: "70%", textAlign: "left", marginBottom: "2%" }}>{t("Voolutugevus (A)")}</Label>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "30%" }}>
                            <Button className="ahutest-form__input --value range button-selection-1" style={{ border: "2px solid white", fontSize: "15px", marginTop: "0", height: "45px" }} onClick={() => handleCheckDimensions("amperage-ozGen")}>Kontrolli</Button>
                            <InfoModal title={t("Juhend")} bodyText={"Märkida tarbitav vool pärast osoonigeneraatori 2 minutilist töötamist. Tarbitava voolu väärtus peab jääma vahemikku 0.35 - 0.65A "} />
                        </div>
                    </div>
                    <Label style={{ display: `${ozonatorTest.measurements["amperage-ozGen"]?.statusNew === undefined ? 'none' : 'block'} ` }}>
                        {ozonatorTest.measurements["amperage-ozGen"]?.statusNew === "OK" ? <div style={{ fontSize: "18px" }}>{t("Test läbitud")} <DoneIcon style={{ fill: "green" }} /></div> : ""}
                        {ozonatorTest.measurements["amperage-ozGen"]?.statusNew === "FAIL" ? <div style={{ fontSize: "18px" }}>{t("Test ebaõnnestunud")}  <CloseIcon style={{ fill: '#FF0000' }} />  </div> : ""}
                    </Label>
                    <MeasurementFieldOzonator propertyName="amperage-ozGen" unit="A" gap="10px" />
                </div>

                <div className="ozTest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Pinge (V)")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Pärast 2 minutilist testi märkida pinge väärtus [V]."} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="currentNew-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="currentNew-ozGen" unit="V" gap="10px" />

                <div className="ozTest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Koroonaplaat töötab vähemalt 90% ulatuses")}</Label>
                    <InfoModal title={t("Juhend")} picture={CeramicPlates} bodyText={"Veenduda, et iga eraldiseisev koroonaplaat töötab vähemalt 90% ulatuses (kui ühe koroonaplaadi pinnast on rohkem kui 2 segmenti kustus on toode praak)."} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="working-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="working-ozGen" showValue={false} />

                <SelectPrinter defaultPrinter="OZ_Printer" />
            </div>
        </form>
    )
}

export default OzGenFunctionTestForm